import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';

@Component({
    selector: 'svg[robocode-close-icon]',
    standalone: true,
    imports: [],
    templateUrl: './close-icon.component.svg',
    styleUrl: './close-icon.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "13",
        height: "13",
        viewBox: "0 0 13 13",
        fill: "none"
    },
})
export class CloseIconComponent {
    color: InputSignal<string> = input<string>('var(--close-icon-color, #D3D3D3)');
}
